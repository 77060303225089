import React, { createContext, useState } from "react";

export const StateContext = createContext();

export default function StateProvider({ children }) {
	// CampaignEmotion state
	const [loading, setLoading] = useState(false);
	const [emotions, setEmotions] = useState([]);
	const [emotionsByDemography, setEmotionsByDemography] = useState([]);
	const [demographics, setDemographics] = useState([]);
	const [demographicsByEmotion, setDemographicsByEmotion] = useState([]);

	// CampaignEmotionTrack -> CampaignEmotion handler
	const handleCampaignEmotionClick = (id) => {
		let newEmotions = [];

		emotions.map((demography,index) => {
			newEmotions.push(demography.map((emotion) => {
				if (emotion.id === id) {
					return { ...emotion, active: !emotion.active };
				}
				return emotion;
			}))
		})

		setEmotions(
			newEmotions
		);
	};

	// CampaignEmotionTrack -> CampaignEmotion handler
	const handleCampaignEmotionClickByDemography = (id) => {

		setEmotionsByDemography(
			emotionsByDemography.map((item) => {
				if (item.id === id) {
					return { ...item, active: true };
				}else{
					return { ...item, active: false };
				}
				return item;
			})
		);
	};

	// CampaignDemographyTrack -> CampaignDemography handler
	const handleCampaignDemographyClick = (id) => {

		setDemographics(
			demographics.map((item) => {
				if (item.id === id) {
					return { ...item, active: true };
				}else{
					return { ...item, active: false };
				}
				return item;
			})
		);
	};


	// CampaignDemographyTrack -> CampaignDemography handler
	const handleCampaignDemographyClickByEmotion = (id) => {

		let newEmotions = [];

		emotionsByDemography.map((emotion,index) => {
			newEmotions.push({...emotion, demographics: emotion.demographics.map((demography) => {
				if (demography.id === id) {
					return { ...demography, active: !demography.active };
				}
				return demography;
			})})
		})

		setEmotionsByDemography(
			newEmotions
		);

		setDemographicsByEmotion(
			demographicsByEmotion.map((item) => {
				if (item.id === id) {
					return { ...item, active: !item.active };
				}
				return item;
			})
		);
	};

	// CampaignEmotionTrack -> ClearButton handler
	const handleClear = () => {
		let newEmotions = [];

		emotions.map((demography,index) => {
			newEmotions.push(demography.map((emotion) => {
				return { ...emotion, active: false };
			}))
		})

		setEmotions(
			newEmotions
		);
	};
	// CampaignEmotionTrack -> ClearButton handler
	const handleDemographyClear = () => {

		let newEmotions = [];

		emotionsByDemography.map((emotion,index) => {
			newEmotions.push({...emotion, demographics: emotion.demographics.map((demography) => {
					return { ...demography, active: false };
				})})
		})

		setEmotionsByDemography(
			newEmotions
		);

		setDemographicsByEmotion(
			demographicsByEmotion.map((item) => {
				return { ...item, active: false };
			})
		);
	};

	const clearEmotions = () => {
		setEmotions([]);
	};

	return (
		<StateContext.Provider
			value={{
				emotions,
				emotionsByDemography,
				demographics,
				demographicsByEmotion,
				handleCampaignEmotionClick,
				handleCampaignDemographyClick,
				handleCampaignEmotionClickByDemography,
				handleCampaignDemographyClickByEmotion,
				handleClear,
				handleDemographyClear,
				setEmotions,
				setEmotionsByDemography,
				setDemographics,
				setDemographicsByEmotion,
				clearEmotions
			}}
		>
			{children}
		</StateContext.Provider>
	);
}
